<template>
  <div class="close-large" @click="close">
    <div class="inner"></div>
  </div>
</template>

<script>
export default {
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.close-large {
  position: absolute;
  right: 0;
  top: 0;
  .inner {
    @include FlexCentered();
    height: 128px;
    width: 128px;
    background-color: $secondary;
    border-radius: 50%;
    box-shadow: $el-shad;
    cursor: pointer;
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: calc(50% - 2px);
      left: calc(50% - 24px);
      width: 48px;
      height: 4px;
      background: #fff;
      transition: transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    &:before {
      transform: translateZ(0) rotate(45deg);
    }
    &:after {
      transform: translateZ(0) rotate(-45deg);
    }
    &:hover {
      &:before,
      &:after {
        transform: none;
      }
    }
  }
  &.black {
    .inner {
      background-color: transparent;
      box-shadow: unset;
      &:before,
      &:after {
        background: $black;
      }
    }
  }
  &.fixed {
    position: fixed;
  }
}
</style>
