<template>
  <div class="app-inner" :class="{ready:pageReady}">
    <div class="return">
      <CloseLarge class="black" @close="initReturn" />
    </div>
    <main class="see-more-main" data-scroll-section>
      <div class="inner">
        <slot></slot>
      </div>
    </main>
  </div>
</template>

<script>
import router from "@/router";
import CloseLarge from "@/components/util/CloseLarge.vue";
export default {
  data() {
    return {
      pageReady:false,
    }
  },
  components: {
    CloseLarge,
  },
  mounted() {
    setTimeout(() => {
      this.pageReady = true
    }, 2000);
  },
  methods: {
    initReturn() {
      router.push("/");
    },
  },
};
</script>

<style lang="scss">
.app-inner {
  .return {
    position: fixed;
    top:0;
    right:0;
    z-index: 9;
  }
  main.see-more-main {
    padding: 4rem 0;
    min-height: 100vh;
    > .inner {
      width: 100%;
      max-width: $break-l;
      margin: 0 auto;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $black;
      }
    }
  }
  &.ready {
    
  }
}
</style>
